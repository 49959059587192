//TODO Configure all required fields

import i18n from "../../i18n";

const activeCampaignPopupData = (
	togglePopup,
	state,
	createCampaign,
	editCampaign,
	archiveCampaign,
	deleteCampaign,
	sendEmail,
	duplicateCampaign
) => {
	const createPopupData = {
		title: "criar_e_gerir_campanhas.create_popup.title.create_camp",
		popupItems: [
			{
				label: "criar_e_gerir_campanhas.edit_popup.field.camp",
				hasBorder: false,
				type: "input",
				required: true,
				name: "utm_campaign",
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.url_redirect",
				hasBorder: false,
				type: "input",
				name: "url_redirect",
				placeholder: "criar_e_gerir_campanhas.create_popup.placeholder.url_redirect_ph",
				hasTooltip: true,
				tooltipHeader: "Criar campanha com URL Redirect",
				tooltipP1: ` Ao criar uma campanha com o URL de Redirecionamento este valor
        será assumido por defeito como URL na criação do Link
        Personalizado.`,
				tooltipP2: `No momento da criação do Link Personalizado se o entender pode
        alterar o URL de Redirecionamento em causa.`,
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.shortener",
				hasBorder: false,
				type: "select-normal",
				items: [...state.teamSelected.shorteners].sort((a, b) => a.name.localeCompare(b.name)),
				required: true,
				name: "name",
				cleanable: false,
				hasTooltip: true,
				tooltipHeader: "criar_e_gerir_campanhas.create_popup.tooltip.create_camp_option_link_short",
				tooltipP1: "criar_e_gerir_campanhas.create_popup.tooltip.shortener_tt_text_p1",
				tooltipP2: "criar_e_gerir_campanhas.create_popup.tooltip.shortener_tt_text_p2",
			},
			{
				label: "criar_e_gerir_campanhas.table.header_text.notes",
				hasBorder: true,
				type: "input",
				required: false,
				name: "notes",
			},
			{
				hasBorder: true,
				type: "date",
				name: "date",
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.teams_select",
				hasBorder: false,
				type: "select-multiple",
				items: state.teams.filter((team) => team.status === "active"),
				firstLocked: true,
				required: false,
				name: "teams",
			},
		],
		required: ["utm_campaign", "startDate", "endDate", "teams"],
		submit: "criar_e_gerir_campanhas.create_popup.button.save_create_ca_m",
		closePopup: togglePopup,
		returnURL: "/campaigns/active-campaigns",
		formAction: "",
		hasOutput: false,
		onSubmit: createCampaign,
	};

	const campaignTaxonomyPopupData = {
		title: "criar_e_gerir_campanhas.create_popup.title.create_camp",
		popupItems: [
			{
				type: "campaign_parameters",
				fields: state.teamSelected.campaign_parameters.filter((param) =>
					state.teamSelected.team_taxonomy.includes(param.name)
				),
				required: true,
				items: state.campaign_parameters,
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.url_redirect",
				hasBorder: false,
				type: "input",
				name: "url_redirect",
				placeholder: "criar_e_gerir_campanhas.create_popup.placeholder.url_redirect_ph",
				hasTooltip: true,
				tooltipHeader: "Criar campanha com URL Redirect",
				tooltipP1: ` Ao criar uma campanha com o URL de Redirecionamento este valor
        será assumido por defeito como URL na criação do Link
        Personalizado.`,
				tooltipP2: `No momento da criação do Link Personalizado se o entender pode
        alterar o URL de Redirecionamento em causa.`,
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.shortener",
				hasBorder: false,
				type: "select-normal",
				items: [...state.teamSelected.shorteners].sort((a, b) => a.name.localeCompare(b.name)),
				required: true,
				name: "name",
				cleanable: false,
				hasTooltip: true,
				tooltipHeader: "criar_e_gerir_campanhas.create_popup.tooltip.create_camp_option_link_short",
				tooltipP1: `criar_e_gerir_campanhas.create_popup.tooltip.shortener_tt_text_p1`,
				tooltipP2: `criar_e_gerir_campanhas.create_popup.tooltip.shortener_tt_text_p2`,
			},
			{
				label: "criar_e_gerir_campanhas.table.header_text.notes",
				hasBorder: true,
				type: "input",
				required: false,
				name: "notes",
			},
			{
				hasBorder: true,
				type: "date",
				name: "date",
				subtext: true,
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.teams_select",
				hasBorder: false,
				type: "select-multiple",
				items: state.teams.filter((team) => team.status === "active"),
				required: true,
				firstLocked: true,
				name: "teams",
			},
		],
		required: [
			"startDate",
			"endDate",
			"teams",
			...state.teamSelected.campaign_parameters
				.filter((param) => state.teamSelected.team_taxonomy.includes(param.name))
				.map((parameter) => parameter.name),
		],
		submit: "criar_e_gerir_campanhas.create_popup.button.save_create_ca_m",
		closePopup: togglePopup,
		returnURL: "/campaigns/active-campaigns",
		formAction: "",
		hasOutput: false,
		hasTaxonomy: true,
		taxonomy: state.teamSelected.team_taxonomy,
		onSubmit: createCampaign,
	};

	const editPopupData = {
		title: "criar_e_gerir_campanhas.edit_popup.title.edit_camp",
		popupItems: [
			{
				label: "criar_e_gerir_campanhas.edit_popup.field.camp",
				hasBorder: false,
				type: "input",
				required: true,
				name: "utm_campaign",
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.url_redirect",
				hasBorder: false,
				type: "input",
				name: "url_redirect",
			},
			{
				label: "criar_e_gerir_campanhas.table.header_text.notes",
				hasBorder: true,
				type: "input",
				required: false,
				name: "notes",
			},
			{
				hasBorder: true,
				type: "date",
				name: "date",
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.teams_select",
				hasBorder: false,
				type: "select-multiple",
				items: state.teams.filter((team) => team.status === "active"),
				firstLocked: true,
				required: false,
				name: "teams",
			},
		],
		required: ["utm_campaign", "startDate", "endDate", "teams"],
		submit: "criar_e_gerir_utilizadores.invite_popup.button.save",
		closePopup: togglePopup,
		returnURL: "/campaigns/active-campaigns",
		formAction: "",
		hasOutput: false,
		onSubmit: editCampaign,
	};

	const editPopupTaxonomyData = {
		title: "criar_e_gerir_campanhas.edit_popup.title.edit_camp",
		popupItems: [
			{
				type: "campaign_parameters",
				fields: state.teamSelected.campaign_parameters.filter((param) =>
					state.teamSelected.team_taxonomy.includes(param.name)
				),

				required: true,
				items: state.campaign_parameters,
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.url_redirect",
				hasBorder: false,
				type: "input",
				name: "url_redirect",
				hasTooltip: true,
				tooltipHeader: "Criar campanha com URL Redirect",
				tooltipP1: ` Ao criar uma campanha com o URL de Redirecionamento este valor
        será assumido por defeito como URL na criação do Link
        Personalizado.`,
				tooltipP2: `No momento da criação do Link Personalizado se o entender pode
        alterar o URL de Redirecionamento em causa.`,
			},
			{
				label: "criar_e_gerir_campanhas.table.header_text.notes",
				hasBorder: true,
				type: "input",
				required: false,
				name: "notes",
			},
			{
				hasBorder: true,
				type: "date",
				name: "date",
				subtext: true,
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.teams_select",
				hasBorder: false,
				type: "select-multiple",
				items: state.teams.filter((team) => team.status === "active"),
				firstLocked: true,
				required: true,
				name: "teams",
			},
		],
		required: [
			"startDate",
			"endDate",
			"teams",
			...state.teamSelected.campaign_parameters
				.filter((param) => state.teamSelected.team_taxonomy.includes(param.name))
				.map((parameter) => parameter.name),
		],
		submit: "criar_e_gerir_utilizadores.invite_popup.button.save",
		closePopup: togglePopup,
		returnURL: "/campaigns/active-campaigns",
		formAction: "",
		hasOutput: false,
		hasTaxonomy: true,
		taxonomy: state.teamSelected.team_taxonomy,
		onSubmit: editCampaign,
	};

	const duplicatePopupData = {
		title: "criar_e_gerir_campanhas.duplicate_popup.title.ca_copy",
		popupItems: [
			{
				label: "criar_e_gerir_campanhas.edit_popup.field.camp",
				hasBorder: false,
				type: "input",
				required: true,
				name: "utm_campaign",
			},
			{
				label: "criar_e_gerir_campanhas.duplicate_popup.checkbox.copy_links_camp",
				hasBorder: true,
				type: "checkbox",
				name: "withLinks",
				value: "",
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.url_redirect",
				hasBorder: false,
				type: "input",
				name: "url_redirect",
			},
			{
				label: "criar_e_gerir_links.create_popup.field.shortener",
				hasBorder: false,
				type: "select-normal",
				items: [...state.teamSelected.shorteners].sort((a, b) => a.name.localeCompare(b.name)),
				required: false,
				name: "name",
			},
			{
				label: "criar_e_gerir_campanhas.table.header_text.notes",
				hasBorder: true,
				type: "input",
				required: false,
				name: "notes",
			},
			{
				hasBorder: true,
				type: "date",
				name: "date",
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.teams_select",
				hasBorder: false,
				type: "select-multiple",
				items: state.teams.filter((team) => team.status === "active"),
				required: false,
				name: "teams",
			},
		],
		required: ["utm_campaign", "startDate", "endDate", "teams"],
		submit: "criar_e_gerir_links.duplicate_popup.button.save_changes_m",
		closePopup: togglePopup,
		returnURL: "/campaigns/active-campaigns",
		formAction: "",
		hasOutput: false,
		onSubmit: duplicateCampaign,
	};

	const duplicatePopupTaxonomyData = {
		title: "criar_e_gerir_campanhas.duplicate_popup.title.ca_copy",
		popupItems: [
			{
				type: "campaign_parameters",
				fields: state.teamSelected.campaign_parameters.filter((param) =>
					state.teamSelected.team_taxonomy.includes(param.name)
				),
				required: true,
				items: state.campaign_parameters,
			},
			{
				label: "criar_e_gerir_campanhas.duplicate_popup.checkbox.copy_links_camp",
				hasBorder: true,
				type: "checkbox",
				name: "withLinks",
				value: "",
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.url_redirect",
				hasBorder: false,
				type: "input",
				name: "url_redirect",
				hasTooltip: true,
				tooltipHeader: "criar_e_gerir_campanhas.create_popup.tooltip.create_camp_option_link_short",
				tooltipP1: `criar_e_gerir_campanhas.create_popup.tooltip.shortener_tt_text_p1`,
				tooltipP2: `criar_e_gerir_campanhas.create_popup.tooltip.shortener_tt_text_p2`,
			},
			{
				label: "criar_e_gerir_campanhas.table.header_text.notes",
				hasBorder: true,
				type: "input",
				required: false,
				name: "notes",
			},
			{
				hasBorder: true,
				type: "date",
				name: "date",
				subtext: true,
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.teams_select",
				hasBorder: false,
				type: "select-multiple",
				items: state.teams.filter((team) => team.status === "active"),
				required: true,
				name: "teams",
			},
		],
		required: [
			"startDate",
			"endDate",
			"teams",
			...state.teamSelected.campaign_parameters
				.filter((param) => state.teamSelected.team_taxonomy.includes(param.name))
				.map((parameter) => parameter.name),
		],
		submit: "criar_e_gerir_links.duplicate_popup.button.save_changes_m",
		closePopup: togglePopup,
		returnURL: "/campaigns/active-campaigns",
		formAction: "",
		hasOutput: false,
		hasTaxonomy: true,
		taxonomy: state.teamSelected.team_taxonomy,
		onSubmit: duplicateCampaign,
	};

	const deleteWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "criar_e_gerir_campanhas.delete_popup.text.cas_delete_confirm_question",
		message: "criar_e_gerir_campanhas.delete_popup.text.cas_delete_confirm_text",
		listData: [
			{
				title: "criar_e_gerir_campanhas.edit_popup.field.camp",
				name: "utm_campaign",
			},
		],
		submit: "criar_e_gerir_valores_de_parâmetros_de_campanha.delete_popup.button.delete_m",
		closePopup: togglePopup,
		returnURL: "/campaigns/active-campaigns",
		formAction: "",
		onSubmit: deleteCampaign,
	};

	const archiveWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "criar_e_gerir_campanhas.archive_popup.text.cas_archive_confirm_question",
		message: "criar_e_gerir_campanhas.archive_popup.text.cas_archive_confirm_text",
		listData: [
			{
				title: "criar_e_gerir_campanhas.edit_popup.field.camp",
				name: "utm_campaign",
			},
		],
		submit: "criar_e_gerir_campanhas.archive_popup.button.archive_m",
		closePopup: togglePopup,
		returnURL: "/campaigns/active-campaigns",
		formAction: "",
		onSubmit: archiveCampaign,
	};

	const emailWarningData = {
		title: "criar_e_gerir_campanhas.email_popup.title.ca_email_title",
		popupItems: [
			{
				type: "emailWarning",
			},
		],
		required: ["users"],
		submit: "dashboard.reminder_popup.button.send_m",
		closePopup: togglePopup,
		returnURL: "/campaigns/active-campaigns",
		formAction: "",
		hasOutput: false,
		onSubmit: sendEmail,
		emailMessage: true,
	};
	return {
		createPopupData,
		campaignTaxonomyPopupData,
		editPopupData,
		duplicatePopupData,
		deleteWarningData,
		archiveWarningData,
		emailWarningData,
		editPopupTaxonomyData,
		duplicatePopupTaxonomyData,
	};
};

const archivedCampaignPopupData = (
	togglePopup,
	handleFormSubmission,
	handleWarningAction,
	state,
	deleteCampaignsLinks,
	activateCampaignCopy,
	activateCampaignsLinks
) => {
	const clonePopupData = {
		title: "campaign.campaign_actiuvate_copy",
		popupItems: [
			{
				label: "menu.barra_esquerda.level_2.camps",
				hasBorder: false,
				type: "input",
				required: true,
				name: "utm_campaign",
			},
			{
				label: "geral.url_redirect",
				hasBorder: false,
				type: "input",
				required: true,
				name: "url_redirect",
			},
			{
				label: "geral.shortner",
				hasBorder: false,
				type: "select-normal",
				items: [...state.teamSelected.shorteners].sort((a, b) => a.name.localeCompare(b.name)),
				required: false,
				name: "name",
			},
			{
				label: "geral.notes",
				hasBorder: true,
				type: "input",
				required: false,
				name: "notes",
			},
			{
				hasBorder: true,
				type: "date",
				name: "date",
			},
			{
				label: "geral.teams_select",
				hasBorder: false,
				type: "select-multiple",
				items: state.teams,
				required: false,
				name: "teams",
			},
		],
		submit: "geral.save_copy",
		closePopup: togglePopup,
		returnURL: "/campaigns/archive",
		formAction: "",
		hasOutput: false,
		onSubmit: activateCampaignCopy,
	};

	const clonePopupTaxonomyData = {
		title: "campaign.campaign_actiuvate_copy",
		popupItems: [
			{
				type: "message",
				hasBorder: true,
				messageHeader: "config.campaign_structure",
				messageStatus: "config.campaign_structure_status",
				messageTaxonomy: state.teamSelected.team_taxonomy
					.map((tax) => {
						switch (tax) {
							case "year":
								tax = "Ano";
								break;
							case "month_full":
								tax = "Mês (Mmm)";
								break;
							case "month":
								tax = "Mês (MM)";
								break;
							case "day":
								tax = "Dia";
								break;
							case "name":
								tax = "Nome Campanha";
								break;
							default:
								break;
						}
						return ["/", "-", "_"].includes(tax) ? tax : ["[", tax, "]"].join("");
					})
					.join(" "),
				messageOption: "config.campaign_structure_change",
				messageButton: "config.structure_change",
				messageLink: "/campaigns/campaign-taxonomy",
			},
			{
				type: "campaign_parameters",
				fields: state.teamSelected.campaign_parameters.filter((param) =>
					state.teamSelected.team_taxonomy.includes(param.name)
				),
				items: state.campaign_parameters,
			},
			{
				label: "criar_e_gerir_campanhas.edit_popup.field.camp",
				hasBorder: false,
				type: "input",
				required: true,
				name: "name_campaign",
			},

			{
				label: "geral.url_redirect",
				hasBorder: false,
				type: "input",
				required: true,
				name: "url_redirect",
				hasTooltip: true,
				tooltipHeader: "Criar campanha com URL Redirect",
				tooltipP1: ` Ao criar uma campanha com o URL de Redirecionamento este valor
        será assumido por defeito como URL na criação do Link
        Personalizado.`,
				tooltipP2: `No momento da criação do Link Personalizado se o entender pode
        alterar o URL de Redirecionamento em causa.`,
			},
			{
				hasBorder: true,
				type: "date",
				name: "date",
				subtext: true,
			},
			{
				label: "geral.teams_select",
				hasBorder: false,
				type: "select-multiple",
				items: state.teams,
				required: true,
				name: "teams",
			},
		],
		submit: "criar_e_gerir_links.duplicate_popup.button.save_changes_m",
		closePopup: togglePopup,
		returnURL: "/campaigns/archive",
		formAction: "",
		hasOutput: false,
		onSubmit: activateCampaignCopy,
	};

	const deleteWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "campanhas_arquivadas.delete_popup.text.delete_confirm_question",
		message: "configurar_link_shorteners.delete_popup.text.confirm_info",
		listData: [
			{
				title: "criar_e_gerir_campanhas.edit_popup.field.camp",
				name: "utm_campaign",
			},
		],
		secondListData: [
			{
				title: "criar_e_gerir_links.delete_popup.text.links",
				name: "utm_campaign",
			},
		],
		submit: "criar_e_gerir_valores_de_parâmetros_de_campanha.delete_popup.button.delete_m",
		closePopup: togglePopup,
		returnURL: "/campaigns/archive",
		formAction: "",
		onSubmit: deleteCampaignsLinks,
	};

	const activateWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "campanhas_arquivadas.reactivate_popup.text.activate_confirm_question",
		message: "gerir_lixo_campanhas_e_links.reactivate_popup.text.eliminate_confirm_info",
		listData: [
			{
				title: "menu.barra_esquerda.level_2.camps",
				name: "utm_campaign",
			},
		],
		secondListData: [
			{
				title: "criar_e_gerir_links.delete_popup.text.links",
				name: "utm_campaign",
			},
		],
		submit: "campanhas_arquivadas.reactivate_popup.button.activate_m",
		closePopup: togglePopup,
		returnURL: "/campaigns/archive",
		formAction: "",
		onSubmit: activateCampaignsLinks,
	};
	return {
		clonePopupData,
		clonePopupTaxonomyData,
		deleteWarningData,
		activateWarningData,
	};
};

const campaignLinksPopupData = (togglePopup, state, createLink, editLink, deleteLink, closeCampaign) => {
	const createPopupData = {
		title: "criar_e_gerir_links.create_popup.title.create_custom_link",
		popupItems: [
			{
				label: "criar_e_gerir_campanhas.edit_popup.field.camp",
				hasBorder: false,
				type: "select-normal",
				required: true,
				populates: state.campaigns,
				populate_fields: ["url_redirect", "name", "utm_id"],
				onlyAvailable: true,
				items: state.campaigns.filter((campaign) => {
					if (campaign.finished) {
						return !campaign.finished[state.teamSelected.id];
					} else {
						return true;
					}
				}),
				name: "utm_campaign",
			},
			{
				label: "menu.barra_esquerda.level_2.template",
				hasBorder: false,
				type: "select-normal",
				required: false,
				populates: state.templates,
				populatesCustom: true,
				populate_fields: [
					"utm_medium",
					"utm_source",
					"utm_content",
					"utm_term",
					"utm_source_platform",
					"utm_creative_format",
					"utm_marketing_tactic",
					"utm_campaign_id",
					"notes",
				],
				items: state.templates,
				name: "template",
			},
			{
				label: "utm_medium",
				hasBorder: false,
				type: "select",
				visible: !state.teamSelected.parameters.find((param) => param.name === "utm_medium").active,
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_medium").categoryId
				),
				name: "utm_medium",
			},
			{
				label: "utm_source",
				hasBorder: false,
				type: "select",
				visible: !state.teamSelected.parameters.find((param) => param.name === "utm_source").active,
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_source").categoryId
				),
				name: "utm_source",
			},
			{
				label: "utm_content",
				hasBorder: false,
				type: "select",
				visible: !state.teamSelected.parameters.find((param) => param.name === "utm_content").active,
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_content").categoryId
				),
				name: "utm_content",
				hasConcatonatedParams: true,
				concatonatedSeparator: state.teamSelected.concat_separator,
				concatonatedParamType: "utm_content",
				concatonatedParamsVisible: state.team && state.teamSelected.concatonated.includes("utm_content"),
				concatonatedParamText: "criar_e_gerir_links.create_popup.button.content_concat_button",
				concatonatedParamFields: state.teamSelected.parameters.filter(
					(utm) => utm.type === "Concat_Content" && utm.active
				),
				concatonatedItems: state.utm_data,
				tooltipHeader: "criar_e_gerir_links.create_popup.tooltip.content_concat_header_tt",
				tooltipP1: `criar_e_gerir_links.create_popup.tooltip.content_concat_p1_tt`,
				tooltipP2: `criar_e_gerir_links.create_popup.tooltip.term_concat_p2_tt`,
				listHeader: `criar_e_gerir_links.create_popup.tooltip.content_concat_list_header_tt`,
				listItems: [
					`criar_e_gerir_links.create_popup.tooltip.content_concat_list_item_1_tt`,
					`criar_e_gerir_links.create_popup.tooltip.content_concat_list_item_2_tt`,
					`criar_e_gerir_links.create_popup.tooltip.criativity_name`,
				],
				tooltipP3: `criar_e_gerir_links.create_popup.tooltip.content_concat_p3_tt
      `,
				tooltipStrong: `criar_e_gerir_links.create_popup.tooltip.content_concat_p3_highlight_tt`,
				tooltipP4: `criar_e_gerir_links.create_popup.tooltip.content_concat_p4_tt`,
			},
			{
				label: "utm_term",
				hasBorder: false,
				type: "select",
				visible: !state.teamSelected.parameters.find((param) => param.name === "utm_term").active,
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_term").categoryId
				),
				name: "utm_term",
				hasConcatonatedParams: true,
				concatonatedSeparator: state.teamSelected.concat_separator,
				concatonatedParamType: "utm_term",
				concatonatedParamsVisible: state.team && state.teamSelected.concatonated.includes("utm_term"),
				concatonatedParamText: "criar_e_gerir_links.create_popup.button.term_concat_button",
				concatonatedParamFields: state.teamSelected.parameters.filter(
					(utm) => utm.type === "Concat_Term" && utm.active
				),
				concatonatedItems: state.utm_data,
				tooltipHeader: "criar_e_gerir_links.create_popup.tooltip.term_concat_header_tt",
				tooltipP1: `criar_e_gerir_links.create_popup.tooltip.content_concat_p1_tt`,
				tooltipP2: `criar_e_gerir_links.create_popup.tooltip.term_concat_p2_tt`,
				listHeader: `criar_e_gerir_links.create_popup.tooltip.content_concat_list_header_tt`,
				listItems: [
					`criar_e_gerir_links.create_popup.tooltip.term_concat_list_item_1_tt`,
					`criar_e_gerir_links.create_popup.tooltip.term_concat_list_item_2_tt`,
				],
				tooltipP3: `criar_e_gerir_links.create_popup.tooltip.term_concat_p3_tt`,
				tooltipStrong: `criar_e_gerir_links.create_popup.tooltip.term_concat_p3_highlight_tt`,
				tooltipP4: `criar_e_gerir_links.create_popup.tooltip.term_concat_p4_tt`,
			},
			...state.teamSelected.parameters
				.filter((param) => param.active)
				.filter(
					(param) =>
						param.type === "Primary" &&
						!["utm_campaign", "utm_medium", "utm_source", "utm_content", "utm_term"].includes(param.name)
				)
				.map((parameter) => {
					return {
						label: parameter.name,
						hasBorder: false,
						type: "select",
						items: state.utm_data.filter(
							(utm) =>
								utm.categoryId ===
								state.teamSelected.parameters.find((param) => param.name === parameter.name).categoryId
						),
						name: `${parameter.name.toLowerCase().replace(/\ /g, "_")}`,
					};
				}),
			{
				type: "custom_parameters",
				fields: state.teamSelected.parameters.filter((utm) => utm.type === "Custom" && utm.active),
				items: state.utm_data.filter((utm) => utm.type === "custom"),
			},
			{
				type: "custom-param-add",
				hasBorder: true,
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.url_redirect",
				hasHTTPSelect: true,
				hasBorder: true,
				required: true,
				type: "input",
				name: "url_redirect",
				placeholder: "criar_e_gerir_campanhas.create_popup.placeholder.url_redirect_ph",
			},
			{
				label: "criar_e_gerir_links.create_popup.field.shortener",
				hasBorder: false,
				type: "select-normal",
				items: [...state.teamSelected.shorteners].sort((a, b) => a.name.localeCompare(b.name)),
				cleanable: false,
				name: "name",
			},
			{
				label: "criar_e_gerir_campanhas.table.header_text.notes",
				hasBorder: false,
				type: "input",
				name: "notes",
				value: "",
			},
			{
				label: "criar_e_gerir_links.create_popup.checkbox.create_template_from_link",
				hasBorder: true,
				type: "checkbox",
				name: "create-template",
				value: "",
			},
		],
		required: ["utm_campaign", "url_redirect"],
		submit: "criar_e_gerir_links.create_popup.button.save_create_m",
		closePopup: togglePopup,
		returnURL: "/links/active-links",
		formAction: "",
		hasOutput: true,
		outputValues: ["https://"],
		onSubmit: createLink,
	};
	const editPopupData = {
		title: "criar_e_gerir_links.edit_popup.title.edit_link",
		popupItems: [
			{
				type: "message",
				hasBorder: true,
				messageHeader: "criar_e_gerir_links.edit_popup.text.edit_link_bitly",
				messageStatus: "criar_e_gerir_links.edit_popup.text.edit_link_bitly_info",
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.url_redirect",
				hasHTTPSelect: true,
				hasBorder: false,
				required: true,
				type: "input",
				name: "url_redirect",
			},
			{
				label: "criar_e_gerir_campanhas.edit_popup.field.camp",
				hasBorder: false,
				type: "select-normal",
				required: true,
				populates: state.campaigns,
				populate_fields: ["url_redirect", "shortener"],
				items: state.campaigns,
				name: "utm_campaign",
			},
			{
				label: "utm_medium",
				hasBorder: false,
				type: "select",
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_medium").categoryId
				),
				name: "utm_medium",
			},
			{
				label: "utm_source",
				hasBorder: false,
				type: "select",
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_source").categoryId
				),
				name: "utm_source",
			},
			{
				label: "utm_content",
				hasBorder: false,
				type: "select",
				visible: !state.teamSelected.parameters.find((param) => param.name === "utm_content").active,
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_content").categoryId
				),
				name: "utm_content",
				hasConcatonatedParams: true,
				concatonatedSeparator: state.teamSelected.concat_separator,
				concatonatedParamType: "utm_content",
				concatonatedParamsVisible: state.team && state.teamSelected.concatonated.includes("utm_content"),
				concatonatedParamText: "criar_e_gerir_links.create_popup.button.content_concat_button",
				concatonatedParamFields: state.teamSelected.parameters.filter(
					(utm) => utm.type === "Concat_Content" && utm.active
				),
				concatonatedItems: state.utm_data,
				tooltipHeader: "criar_e_gerir_links.create_popup.tooltip.content_concat_header_tt",
				tooltipP1: `criar_e_gerir_links.create_popup.tooltip.content_concat_p1_tt`,
				tooltipP2: `criar_e_gerir_links.create_popup.tooltip.content_concat_p2_tt`,
				listHeader: `criar_e_gerir_links.create_popup.tooltip.content_concat_list_header_tt`,
				listItems: [
					`criar_e_gerir_links.create_popup.tooltip.content_concat_list_item_1_tt`,
					`criar_e_gerir_links.create_popup.tooltip.content_concat_list_item_2_tt`,
					`criar_e_gerir_links.create_popup.tooltip.criativity_name`,
				],
				tooltipP3: `criar_e_gerir_links.create_popup.tooltip.content_concat_p3_tt
      `,
				tooltipStrong: `criar_e_gerir_links.create_popup.tooltip.content_concat_p3_highlight_tt`,
				tooltipP4: `criar_e_gerir_links.create_popup.tooltip.content_concat_p4_tt`,
			},
			{
				label: "utm_term",
				hasBorder: false,
				type: "select",
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_term").categoryId
				),
				name: "utm_term",
				visible: !state.teamSelected.parameters.find((param) => param.name === "utm_term").active,
				hasConcatonatedParams: true,
				concatonatedSeparator: state.teamSelected.concat_separator,
				concatonatedParamType: "utm_term",
				concatonatedParamsVisible: state.team && state.teamSelected.concatonated.includes("utm_term"),
				concatonatedParamText: "criar_e_gerir_links.create_popup.button.term_concat_button",
				concatonatedParamFields: state.teamSelected.parameters.filter(
					(utm) => utm.type === "Concat_Term" && utm.active
				),
				concatonatedItems: state.utm_data,
				tooltipHeader: "criar_e_gerir_links.create_popup.tooltip.term_concat_header_tt",
				tooltipP1: `criar_e_gerir_links.create_popup.tooltip.content_concat_p1_tt`,
				tooltipP2: `criar_e_gerir_links.create_popup.tooltip.term_concat_p2_tt`,
				listHeader: `criar_e_gerir_links.create_popup.tooltip.content_concat_list_header_tt`,
				listItems: [
					`criar_e_gerir_links.create_popup.tooltip.term_concat_list_item_1_tt`,
					`criar_e_gerir_links.create_popup.tooltip.term_concat_list_item_2_tt`,
				],
				tooltipP3: `criar_e_gerir_links.create_popup.tooltip.term_concat_p3_tt`,
				tooltipStrong: `criar_e_gerir_links.create_popup.tooltip.term_concat_p3_highlight_tt`,
				tooltipP4: `criar_e_gerir_links.create_popup.tooltip.term_concat_p4_tt`,
			},
			...state.teamSelected.parameters
				.filter((param) => param.active)
				.filter(
					(param) =>
						param.type === "Primary" &&
						!["utm_campaign", "utm_medium", "utm_source", "utm_content", "utm_term"].includes(param.name)
				)
				.map((parameter) => {
					return {
						label: parameter.name,
						hasBorder: false,
						type: "select",
						items: state.utm_data.filter(
							(utm) =>
								utm.categoryId ===
								state.teamSelected.parameters.find((param) => param.name === parameter.name).categoryId
						),
						name: `${parameter.name.toLowerCase().replace(/\ /g, "_")}`,
					};
				}),
			{
				type: "custom_parameters",
				fields: state.teamSelected.parameters.filter((utm) => utm.type === "Custom" && utm.active),
				items: state.utm_data.filter((utm) => utm.type === "custom"),
			},
			{
				type: "custom-param-add",
				hasBorder: true,
			},
			{
				label: "criar_e_gerir_campanhas.table.header_text.notes",
				hasBorder: true,
				type: "input",
				name: "notes",
				value: "",
			},
		],
		required: ["utm_campaign", "url_redirect"],
		submit: "criar_e_gerir_utilizadores.invite_popup.button.save",
		closePopup: togglePopup,
		returnURL: "/links/active-links",
		formAction: "",
		hasOutput: true,
		outputValues: ["https://"],
		onSubmit: editLink,
	};
	const duplicatePopupData = {
		title: "criar_e_gerir_links.duplicate_popup.title.duplicate_link",
		popupItems: [
			{
				label: "criar_e_gerir_campanhas.edit_popup.field.camp",
				hasBorder: false,
				type: "select-normal",
				required: true,
				populates: state.campaigns,
				populate_fields: ["url_redirect", "shortener"],
				items: state.campaigns,
				name: "utm_campaign",
			},
			{
				label: "menu.barra_esquerda.level_2.template",
				hasBorder: false,
				type: "select-normal",
				required: false,
				populates: state.templates,
				populatesCustom: true,
				populate_fields: [
					"utm_medium",
					"utm_source",
					"utm_content",
					"utm_term",
					"utm_source_platform",
					"utm_creative_format",
					"utm_marketing_tactic",
					"utm_id",
					"utm_campaign_id",
				],
				items: state.templates,
				name: "template",
			},
			{
				label: "utm_medium",
				hasBorder: false,
				type: "select",
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_medium").categoryId
				),
				name: "utm_medium",
			},
			{
				label: "utm_source",
				hasBorder: false,
				type: "select",
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_source").categoryId
				),
				name: "utm_source",
			},
			{
				label: "utm_content",
				hasBorder: false,
				type: "select",
				visible: !state.teamSelected.parameters.find((param) => param.name === "utm_content").active,
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_content").categoryId
				),
				name: "utm_content",
				hasConcatonatedParams: true,
				concatonatedSeparator: state.teamSelected.concat_separator,
				concatonatedParamType: "utm_content",
				concatonatedParamsVisible: state.team && state.teamSelected.concatonated.includes("utm_content"),
				concatonatedParamText: "criar_e_gerir_links.create_popup.button.content_concat_button",
				concatonatedParamFields: state.teamSelected.parameters.filter(
					(utm) => utm.type === "Concat_Content" && utm.active
				),
				concatonatedItems: state.utm_data,
				tooltipHeader: "criar_e_gerir_links.create_popup.tooltip.content_concat_header_tt",
				tooltipP1: `criar_e_gerir_links.create_popup.tooltip.content_concat_p1_tt`,
				tooltipP2: `criar_e_gerir_links.create_popup.tooltip.content_concat_p2_tt`,
				listHeader: `criar_e_gerir_links.create_popup.tooltip.content_concat_list_header_tt`,
				listItems: [
					`criar_e_gerir_links.create_popup.tooltip.content_concat_list_item_1_tt`,
					`criar_e_gerir_links.create_popup.tooltip.content_concat_list_item_2_tt`,
					`criar_e_gerir_links.create_popup.tooltip.criativity_name`,
				],
				tooltipP3: `criar_e_gerir_links.create_popup.tooltip.content_concat_p3_tt
      `,
				tooltipStrong: `criar_e_gerir_links.create_popup.tooltip.content_concat_p3_highlight_tt`,
				tooltipP4: `criar_e_gerir_links.create_popup.tooltip.content_concat_p4_tt`,
			},
			{
				label: "utm_term",
				hasBorder: false,
				type: "select",
				visible: !state.teamSelected.parameters.find((param) => param.name === "utm_term").active,
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_term").categoryId
				),
				name: "utm_term",
				hasConcatonatedParams: true,
				concatonatedSeparator: state.teamSelected.concat_separator,
				concatonatedParamType: "utm_term",
				concatonatedParamsVisible: state.team && state.teamSelected.concatonated.includes("utm_term"),
				concatonatedParamText: "criar_e_gerir_links.create_popup.button.term_concat_button",
				concatonatedParamFields: state.teamSelected.parameters.filter(
					(utm) => utm.type === "Concat_Term" && utm.active
				),
				concatonatedItems: state.utm_data,
				tooltipHeader: "criar_e_gerir_links.create_popup.tooltip.term_concat_header_tt",
				tooltipP1: `criar_e_gerir_links.create_popup.tooltip.content_concat_p1_tt`,
				tooltipP2: `criar_e_gerir_links.create_popup.tooltip.term_concat_p2_tt`,
				listHeader: `criar_e_gerir_links.create_popup.tooltip.content_concat_list_header_tt`,
				listItems: [
					`criar_e_gerir_links.create_popup.tooltip.term_concat_list_item_1_tt`,
					`criar_e_gerir_links.create_popup.tooltip.term_concat_list_item_2_tt`,
				],
				tooltipP3: `criar_e_gerir_links.create_popup.tooltip.term_concat_p3_tt`,
				tooltipStrong: `criar_e_gerir_links.create_popup.tooltip.term_concat_p3_highlight_tt`,
				tooltipP4: `criar_e_gerir_links.create_popup.tooltip.term_concat_p4_tt`,
			},
			...state.teamSelected.parameters
				.filter((param) => param.active)
				.filter(
					(param) =>
						param.type === "Primary" &&
						!["utm_campaign", "utm_medium", "utm_source", "utm_content", "utm_term"].includes(param.name)
				)
				.map((parameter) => {
					return {
						label: parameter.name,
						hasBorder: false,
						type: "select",
						items: state.utm_data.filter(
							(utm) =>
								utm.categoryId ===
								state.teamSelected.parameters.find((param) => param.name === parameter.name).categoryId
						),
						name: `${parameter.name.toLowerCase().replace(/\ /g, "_")}`,
					};
				}),
			{
				type: "custom_parameters",
				fields: state.teamSelected.parameters.filter((utm) => utm.type === "Custom" && utm.active),
				items: state.utm_data.filter((utm) => utm.type === "custom"),
			},
			{
				type: "custom-param-add",
				hasBorder: true,
			},
			{
				label: "criar_e_gerir_campanhas.create_popup.field.url_redirect",
				hasHTTPSelect: true,
				hasBorder: true,
				required: true,
				type: "input",
				name: "url_redirect",
			},
			{
				label: "criar_e_gerir_links.create_popup.field.shortener",
				hasBorder: false,
				type: "select-normal",
				items: [...state.teamSelected.shorteners].sort((a, b) => a.name.localeCompare(b.name)),
				name: "name",
			},
			{
				label: "criar_e_gerir_campanhas.table.header_text.notes",
				hasBorder: false,
				type: "input",
				name: "notes",
				value: "",
			},
		],
		required: ["utm_campaign", "url_redirect"],
		submit: "criar_e_gerir_links.create_popup.button.save_create_m",
		closePopup: togglePopup,
		returnURL: "/links/active-links",
		formAction: "",
		hasOutput: true,
		outputValues: ["https://"],
		onSubmit: createLink,
	};

	const deleteWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "criar_e_gerir_links.delete_popup.text.delete_confirm_question",
		message: "criar_e_gerir_links.delete_popup.text.delete_confirm_info",
		listData: [
			{
				title: "criar_e_gerir_links.delete_popup.text.links",
				name: "full_url",
			},
		],
		submit: "criar_e_gerir_valores_de_parâmetros_de_campanha.delete_popup.button.delete_m",
		closePopup: togglePopup,
		returnURL: "/links/active-links",
		formAction: "",
		onSubmit: deleteLink,
	};

	const closeCampaignWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "criar_e_gerir_links.finish_popup.text.finish_question",
		message: "criar_e_gerir_links.finish_popup.text.finish_info",
		listData: [
			{
				title: "criar_e_gerir_campanhas.edit_popup.field.camp",
				name: "utm_campaign",
			},
		],
		submit: "criar_e_gerir_links.header.button.finish_m",
		closePopup: togglePopup,
		returnURL: "/links/active-links",
		formAction: "",
		onSubmit: closeCampaign,
	};
	const reopenCampaignWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "criar_e_gerir_links.reopen_popup.text.finish_question",
		message: "criar_e_gerir_links.reopen_popup.text.finish_info",
		listData: [
			{
				title: "criar_e_gerir_campanhas.edit_popup.field.camp",
				name: "utm_campaign",
			},
		],
		submit: "criar_e_gerir_links.reopen_popup.button.reopen_m",
		closePopup: togglePopup,
		returnURL: "/links/active-links",
		formAction: "",
		onSubmit: closeCampaign,
	};

	return {
		createPopupData,
		editPopupData,
		duplicatePopupData,
		deleteWarningData,
		closeCampaignWarningData,
		reopenCampaignWarningData,
	};
};

const templatesPopupData = (
	togglePopup,
	handleFormSubmission,
	handleWarningAction,
	state,
	createTemplate,
	editTemplate,
	deleteTemplate
) => {
	const createPopupData = {
		title: "criar_e_gerir_templates.create_popup.title.create_temp",
		popupItems: [
			{
				label: "criar_e_gerir_templates.table.header_text.name",
				hasBorder: true,
				type: "input",
				required: false,
				name: "name",
			},
			{
				label: "utm_medium",
				hasBorder: false,
				type: "select",
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_medium").categoryId
				),
				name: "utm_medium",
			},
			{
				label: "utm_source",
				hasBorder: false,
				type: "select",
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_source").categoryId
				),
				name: "utm_source",
			},
			{
				label: "utm_content",
				hasBorder: false,
				type: "select",
				visible: !state.team.parameters.find((param) => param.name === "utm_content").active,
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_content").categoryId
				),
				name: "utm_content",
				hasConcatonatedParams: true,
				concatonatedSeparator: state.team.concat_separator,
				concatonatedParamType: "utm_content",
				concatonatedParamsVisible: state.team && state.team.concatonated.includes("utm_content"),
				concatonatedParamText: "criar_e_gerir_links.create_popup.button.content_concat_button",
				concatonatedParamFields: state.team.parameters.filter(
					(utm) => utm.type === "Concat_Content" && utm.active
				),
				concatonatedItems: state.utm_data,
				tooltipHeader: "criar_e_gerir_links.create_popup.tooltip.content_concat_header_tt",
				tooltipP1: "criar_e_gerir_links.create_popup.tooltip.content_concat_p1_tt",
				tooltipP2: "criar_e_gerir_links.create_popup.tooltip.content_concat_p2_tt",
				listHeader: "criar_e_gerir_links.create_popup.tooltip.content_concat_list_header_tt",
				listItems: [
					`criar_e_gerir_links.create_popup.tooltip.content_concat_list_item_1_tt`,
					`criar_e_gerir_links.create_popup.tooltip.content_concat_list_item_2_tt`,
					"criar_e_gerir_links.create_popup.tooltip.criativity_name",
				],
				tooltipP3: "criar_e_gerir_links.create_popup.tooltip.content_concat_p3_tt",
				tooltipStrong: "criar_e_gerir_links.create_popup.tooltip.content_concat_p3_highlight_tt",
				tooltipP4: "criar_e_gerir_links.create_popup.tooltip.content_concat_p4_tt",
			},
			{
				label: "utm_term",
				hasBorder: false,
				type: "select",
				visible: !state.team.parameters.find((param) => param.name === "utm_term").active,
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_term").categoryId
				),
				name: "utm_term",
				hasConcatonatedParams: true,
				concatonatedSeparator: state.team.concat_separator,
				concatonatedParamType: "utm_term",
				concatonatedParamsVisible: state.team && state.team.concatonated.includes("utm_term"),
				concatonatedParamText: "criar_e_gerir_links.create_popup.button.term_concat_button",
				concatonatedParamFields: state.team.parameters.filter(
					(utm) => utm.type === "Concat_Term" && utm.active
				),
				concatonatedItems: state.utm_data,
				tooltipHeader: "criar_e_gerir_links.create_popup.tooltip.term_concat_header_tt",
				tooltipP1: "criar_e_gerir_links.create_popup.tooltip.content_concat_p1_tt",
				tooltipP2: "criar_e_gerir_links.create_popup.tooltip.term_concat_p2_tt",
				listHeader: "criar_e_gerir_links.create_popup.tooltip.content_concat_list_header_tt",
				listItems: [
					`criar_e_gerir_links.create_popup.tooltip.term_concat_list_item_1_tt`,
					`criar_e_gerir_links.create_popup.tooltip.term_concat_list_item_2_tt`,
				],
				tooltipP3: "criar_e_gerir_links.create_popup.tooltip.term_concat_p3_tt",
				tooltipStrong: "criar_e_gerir_links.create_popup.tooltip.term_concat_p3_highlight_tt",
				tooltipP4: "criar_e_gerir_links.create_popup.tooltip.term_concat_p4_tt",
			},
			...state.teamSelected.parameters
				.filter((param) => param.active)
				.filter(
					(param) =>
						param.type === "Primary" &&
						!["utm_campaign", "utm_medium", "utm_source", "utm_content", "utm_term"].includes(param.name)
				)
				.map((parameter) => {
					return {
						label: parameter.name,
						hasBorder: false,
						type: "select",
						items: state.utm_data.filter(
							(utm) =>
								utm.categoryId ===
								state.teamSelected.parameters.find((param) => param.name === parameter.name).categoryId
						),
						name: `${parameter.name.toLowerCase().replace(/\ /g, "_")}`,
					};
				}),
			{
				type: "custom_parameters",
				fields: state.team.parameters.filter((utm) => utm.type === "Custom" && utm.active),
				items: state.utm_data.filter((utm) => utm.type === "custom"),
			},
			{
				type: "custom-param-add",
				hasBorder: true,
			},
			{
				label: "criar_e_gerir_campanhas.table.header_text.notes",
				hasBorder: true,
				type: "input",
				name: "notes",
				value: "",
			},
		],
		submit: "criar_e_gerir_templates.create_popup.button.gravar_template",
		closePopup: togglePopup,
		returnURL: "/links/templates",
		formAction: "",
		onSubmit: createTemplate,
	};
	const editPopupData = {
		title: "criar_e_gerir_templates.duplicate_popup.title.edit_temp",
		popupItems: [
			{
				label: "criar_e_gerir_templates.table.header_text.name",
				hasBorder: true,
				type: "input",
				required: true,
				name: "name",
			},
			{
				label: "utm_medium",
				hasBorder: false,
				type: "select",
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_medium").categoryId
				),
				name: "utm_medium",
			},
			{
				label: "utm_source",
				hasBorder: false,
				type: "select",
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_source").categoryId
				),
				name: "utm_source",
			},
			{
				label: "utm_content",
				hasBorder: false,
				type: "select",
				visible: !state.team.parameters.find((param) => param.name === "utm_content").active,
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_content").categoryId
				),
				name: "utm_content",
				hasConcatonatedParams: true,
				concatonatedSeparator: state.team.concat_separator,
				concatonatedParamType: "utm_content",
				concatonatedParamsVisible: state.team && state.team.concatonated.includes("utm_content"),
				concatonatedParamText: "criar_e_gerir_links.create_popup.button.content_concat_button",
				concatonatedParamFields: state.team.parameters.filter(
					(utm) => utm.type === "Concat_Content" && utm.active
				),
				concatonatedItems: state.utm_data.filter((utm) => utm.type === "concat_content"),
				tooltipHeader: "criar_e_gerir_links.create_popup.tooltip.content_concat_header_tt",
				tooltipP1: "criar_e_gerir_links.create_popup.tooltip.content_concat_p1_tt",
				tooltipP2: "criar_e_gerir_links.create_popup.tooltip.content_concat_p2_tt",
				listHeader: "criar_e_gerir_links.create_popup.tooltip.content_concat_list_header_tt",
				listItems: [
					`criar_e_gerir_links.create_popup.tooltip.content_concat_list_item_1_tt`,
					`criar_e_gerir_links.create_popup.tooltip.content_concat_list_item_2_tt`,
					"criar_e_gerir_links.create_popup.tooltip.criativity_name",
				],
				tooltipP3: "criar_e_gerir_links.create_popup.tooltip.content_concat_p3_tt",
				tooltipStrong: "criar_e_gerir_links.create_popup.tooltip.content_concat_p3_highlight_tt",
				tooltipP4: "criar_e_gerir_links.create_popup.tooltip.content_concat_p4_tt",
			},
			{
				label: "utm_term",
				hasBorder: false,
				type: "select",
				visible: !state.team.parameters.find((param) => param.name === "utm_term").active,
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_term").categoryId
				),
				name: "utm_term",
				hasConcatonatedParams: true,
				concatonatedSeparator: state.team.concat_separator,
				concatonatedParamType: "utm_term",
				concatonatedParamsVisible: state.team && state.team.concatonated.includes("utm_term"),
				concatonatedParamText: "criar_e_gerir_links.create_popup.button.term_concat_button",
				concatonatedParamFields: state.team.parameters.filter(
					(utm) => utm.type === "Concat_Term" && utm.active
				),
				concatonatedItems: state.utm_data.filter((utm) => utm.type === "concat_term"),
				tooltipHeader: "criar_e_gerir_links.create_popup.tooltip.term_concat_header_tt",
				tooltipP1: "criar_e_gerir_links.create_popup.tooltip.content_concat_p1_tt",
				tooltipP2: "criar_e_gerir_links.create_popup.tooltip.term_concat_p2_tt",
				listHeader: "criar_e_gerir_links.create_popup.tooltip.content_concat_list_header_tt",
				listItems: [
					`criar_e_gerir_links.create_popup.tooltip.term_concat_list_item_1_tt`,
					`criar_e_gerir_links.create_popup.tooltip.term_concat_list_item_2_tt`,
				],
				tooltipP3: "criar_e_gerir_links.create_popup.tooltip.term_concat_p3_tt",
				tooltipStrong: "criar_e_gerir_links.create_popup.tooltip.term_concat_p3_highlight_tt",
				tooltipP4: "criar_e_gerir_links.create_popup.tooltip.term_concat_p4_tt",
			},
			...state.teamSelected.parameters
				.filter((param) => param.active)
				.filter(
					(param) =>
						param.type === "Primary" &&
						!["utm_campaign", "utm_medium", "utm_source", "utm_content", "utm_term"].includes(param.name)
				)
				.map((parameter) => {
					return {
						label: parameter.name,
						hasBorder: false,
						type: "select",
						items: state.utm_data.filter(
							(utm) =>
								utm.categoryId ===
								state.teamSelected.parameters.find((param) => param.name === parameter.name).categoryId
						),
						name: `${parameter.name.toLowerCase().replace(/\ /g, "_")}`,
					};
				}),
			{
				type: "custom_parameters",
				fields: state.team.parameters.filter((utm) => utm.type === "Custom" && utm.active),
				items: state.utm_data.filter((utm) => utm.type === "custom"),
			},
			{
				type: "custom-param-add",
				hasBorder: true,
			},
			{
				label: "criar_e_gerir_campanhas.table.header_text.notes",
				hasBorder: true,
				type: "input",
				name: "notes",
				value: "",
			},
		],
		submit: "criar_e_gerir_utilizadores.invite_popup.button.save",
		closePopup: togglePopup,
		returnURL: "/links/templates",
		formAction: "",
		onSubmit: editTemplate,
	};
	const duplicatePopupData = {
		title: "template.template_copy",
		popupItems: [
			{
				label: "criar_e_gerir_templates.table.header_text.name",
				hasBorder: true,
				type: "input",
				required: true,
				name: "name",
			},
			{
				label: "utm_medium",
				hasBorder: false,
				type: "select",
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_medium").categoryId
				),
				name: "utm_medium",
			},
			{
				label: "utm_source",
				hasBorder: false,
				type: "select",
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_source").categoryId
				),
				name: "utm_source",
			},
			{
				label: "utm_content",
				hasBorder: false,
				type: "select",
				visible: !state.team.parameters.find((param) => param.name === "utm_content").active,
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_content").categoryId
				),
				name: "utm_content",
				hasConcatonatedParams: true,
				concatonatedSeparator: state.team.concat_separator,
				concatonatedParamType: "utm_content",
				concatonatedParamsVisible: state.team && state.team.concatonated.includes("utm_content"),
				concatonatedParamText: "criar_e_gerir_links.create_popup.button.content_concat_button",
				concatonatedParamFields: state.team.parameters.filter(
					(utm) => utm.type === "Concat_Content" && utm.active
				),
				concatonatedItems: state.utm_data.filter((utm) => utm.type === "concat_content"),
				tooltipHeader: "criar_e_gerir_links.create_popup.tooltip.content_concat_header_tt",
				tooltipP1: "criar_e_gerir_links.create_popup.tooltip.content_concat_p1_tt",
				tooltipP2: "criar_e_gerir_links.create_popup.tooltip.content_concat_p2_tt",
				listHeader: "criar_e_gerir_links.create_popup.tooltip.content_concat_list_header_tt",
				listItems: [
					`criar_e_gerir_links.create_popup.tooltip.content_concat_list_item_1_tt`,
					`criar_e_gerir_links.create_popup.tooltip.content_concat_list_item_2_tt`,
					`criar_e_gerir_links.create_popup.tooltip.criativity_name`,
				],
				tooltipP3: "criar_e_gerir_links.create_popup.tooltip.content_concat_p3_tt",
				tooltipStrong: "criar_e_gerir_links.create_popup.tooltip.content_concat_p3_highlight_tt",
				tooltipP4: "criar_e_gerir_links.create_popup.tooltip.content_concat_p4_tt",
			},
			{
				label: "utm_term",
				hasBorder: false,
				type: "select",
				visible: !state.team.parameters.find((param) => param.name === "utm_term").active,
				items: state.utm_data.filter(
					(utm) =>
						utm.categoryId ===
						state.teamSelected.parameters.find((param) => param.name === "utm_term").categoryId
				),
				name: "utm_term",
				hasConcatonatedParams: true,
				concatonatedSeparator: state.team.concat_separator,
				concatonatedParamType: "utm_term",
				concatonatedParamsVisible: state.team && state.team.concatonated.includes("utm_term"),
				concatonatedParamText: "criar_e_gerir_links.create_popup.button.term_concat_button",
				concatonatedParamFields: state.team.parameters.filter(
					(utm) => utm.type === "Concat_Term" && utm.active
				),
				concatonatedItems: state.utm_data.filter((utm) => utm.type === "concat_term"),
				tooltipHeader: "criar_e_gerir_links.create_popup.tooltip.term_concat_header_tt",
				tooltipP1: "criar_e_gerir_links.create_popup.tooltip.content_concat_p1_tt",
				tooltipP2: "criar_e_gerir_links.create_popup.tooltip.term_concat_p2_tt",
				listHeader: "criar_e_gerir_links.create_popup.tooltip.content_concat_list_header_tt",
				listItems: [
					`criar_e_gerir_links.create_popup.tooltip.term_concat_list_item_1_tt`,
					`criar_e_gerir_links.create_popup.tooltip.term_concat_list_item_2_tt`,
				],
				tooltipP3: "criar_e_gerir_links.create_popup.tooltip.term_concat_p3_tt",
				tooltipStrong: "criar_e_gerir_links.create_popup.tooltip.term_concat_p3_highlight_tt",
				tooltipP4: "criar_e_gerir_links.create_popup.tooltip.term_concat_p4_tt",
			},
			...state.teamSelected.parameters
				.filter((param) => param.active)
				.filter(
					(param) =>
						param.type === "Primary" &&
						!["utm_campaign", "utm_medium", "utm_source", "utm_content", "utm_term"].includes(param.name)
				)
				.map((parameter) => {
					return {
						label: parameter.name,
						hasBorder: false,
						type: "select",
						items: state.utm_data.filter(
							(utm) =>
								utm.categoryId ===
								state.teamSelected.parameters.find((param) => param.name === parameter.name).categoryId
						),
						name: `${parameter.name.toLowerCase().replace(/\ /g, "_")}`,
					};
				}),
			{
				type: "custom_parameters",
				fields: state.team.parameters.filter((utm) => utm.type === "Custom" && utm.active),
				items: state.utm_data.filter((utm) => utm.type === "custom"),
			},
			{
				type: "custom-param-add",
				hasBorder: true,
			},
			{
				label: "criar_e_gerir_campanhas.table.header_text.notes",
				hasBorder: true,
				type: "input",
				name: "notes",
				value: "",
			},
		],
		submit: "criar_e_gerir_links.duplicate_popup.button.save_changes_m",
		closePopup: togglePopup,
		returnURL: "/links/templates",
		formAction: "",
		onSubmit: createTemplate,
	};

	const deleteWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "criar_e_gerir_templates.duplicate_popup.title.edit_temp",
		message: "criar_e_gerir_templates.delete_popup.text.confirm_info",
		listData: [
			{
				title: "menu.barra_esquerda.level_2.temps",
				name: "name",
			},
		],
		submit: "criar_e_gerir_valores_de_parâmetros_de_campanha.delete_popup.button.delete_m",
		closePopup: togglePopup,
		returnURL: "/links/templates",
		formAction: "",
		onSubmit: deleteTemplate,
	};
	return {
		createPopupData,
		editPopupData,
		duplicatePopupData,
		deleteWarningData,
	};
};

const deletedCampaignsLinksPopupData = (
	togglePopup,
	handleFormSubmission,
	handleWarningAction,
	state,
	activateCampaignsLinks,
	terminateCampaignsLinks
) => {
	const deleteWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "campanhas_arquivadas.delete_popup.text.delete_confirm_question",
		message: "gerir_lixo_campanhas_e_links.eliminate_popup.text.eliminate_confirm_info",
		listData: [
			{
				title: "menu.barra_esquerda.level_2.camps",
				name: "utm_campaign",
			},
		],
		secondListData: [
			{
				title: "criar_e_gerir_links.delete_popup.text.links",
				name: "utm_campaign",
			},
		],
		submit: "criar_e_gerir_valores_de_parâmetros_de_campanha.delete_popup.button.delete_m",
		closePopup: togglePopup,
		returnURL: "/deleted/campaigns-links",
		formAction: "",
		onSubmit: terminateCampaignsLinks,
	};

	const activateWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "campanhas_arquivadas.reactivate_popup.text.activate_confirm_question",
		message: "campanhas_arquivadas.reactivate_popup.text.activate_confirm_info",
		listData: [
			{
				title: "menu.barra_esquerda.level_2.camps",
				name: "utm_campaign",
			},
		],
		secondListData: [
			{
				title: "criar_e_gerir_links.delete_popup.text.links",
				name: "utm_campaign",
			},
		],
		submit: "campanhas_arquivadas.reactivate_popup.button.activate_m",
		closePopup: togglePopup,
		returnURL: "/deleted/campaigns-links",
		formAction: "",
		onSubmit: activateCampaignsLinks,
	};
	return {
		deleteWarningData,
		activateWarningData,
	};
};

const deletedParametersPopupData = (
	togglePopup,
	handleFormSubmission,
	handleWarningAction,
	state,
	activateParameter,
	terminateParameter
) => {
	const deleteWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "criar_e_gerir_valores_de_parâmetros_utm.delete_popup.text.confirm_question",
		message: "criar_e_gerir_valores_de_parâmetros_utm.delete_popup.text.confirm_info",
		listData: [
			{
				title: "criar_e_gerir_parâmetros_campanha.parameter",
				name: "name",
			},
		],
		submit: "criar_e_gerir_valores_de_parâmetros_de_campanha.delete_popup.button.delete_m",
		closePopup: togglePopup,
		returnURL: "/deleted/parameters",
		formAction: "",
		onSubmit: terminateParameter,
	};

	const activateWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "param.param_reactivate_text",
		message: "param.param_activate_text_info",
		listData: [
			{
				title: "criar_e_gerir_parâmetros_campanha.parameter",
				name: "name",
			},
		],
		submit: "campanhas_arquivadas.reactivate_popup.button.activate_m",
		closePopup: togglePopup,
		returnURL: "/deleted/parameters",
		formAction: "",
		onSubmit: activateParameter,
	};
	return {
		deleteWarningData,
		activateWarningData,
	};
};

const deletedTemplatesPopupData = (
	togglePopup,
	handleFormSubmission,
	handleWarningAction,
	state,
	activateTemplate,
	terminateTemplate
) => {
	const deleteWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "gerir_lixo_templates.eliminate_popup.text.eliminate_confirm_question",
		message: "gerir_lixo_templates.eliminate_popup.text.eliminate_confirm_info",
		listData: [
			{
				title: "menu.barra_esquerda.level_2.temps",
				name: "name",
			},
		],
		submit: "criar_e_gerir_valores_de_parâmetros_de_campanha.delete_popup.button.delete_m",
		closePopup: togglePopup,
		returnURL: "/deleted/templates",
		formAction: "",
		onSubmit: terminateTemplate,
	};

	const activateWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "gerir_lixo_templates.reactivate_popup.text.eliminate_confirm_question",
		message: "gerir_lixo_templates.reactivate_popup.text.eliminate_confirm_info",
		listData: [
			{
				title: "menu.barra_esquerda.level_2.temps",
				name: "name",
			},
		],
		submit: "campanhas_arquivadas.reactivate_popup.button.activate_m",
		closePopup: togglePopup,
		returnURL: "/deleted/templates",
		formAction: "",
		onSubmit: activateTemplate,
	};
	return {
		deleteWarningData,
		activateWarningData,
	};
};

const userManagementPopupData = (
	togglePopup,
	handleFormSubmission,
	handleWarningAction,
	state,
	inviteUser,
	editUser,
	deleteUser,
	resendInvite
) => {
	const createPopupData = {
		title: "criar_e_gerir_utilizadores.invite_popup.title.invite_user",
		popupItems: [
			{
				label: "criar_e_gerir_templates.table.header_text.name",
				hasBorder: false,
				type: "input",
				name: "username",
			},
			{
				label: "criar_e_gerir_utilizadores.table.header_text.email",
				hasBorder: false,
				type: "email",
				name: "email",
			},
			{
				label: "criar_e_gerir_utilizadores.invite_popup.field.message",
				hasBorder: false,
				type: "textarea",
				name: "message",
			},
			{
				label: "criar_e_gerir_utilizadores.table.header_text.role",
				hasBorder: false,
				type: "switch",
				infoVals: {
					member: "criar_e_gerir_utilizadores.invite_popup.text.member_perm_info",
					admin: "criar_e_gerir_utilizadores.invite_popup.text.admin_perm_info",
				},
				items: [
					{ role: "member", label: "dashboard.user_grid.header_text.member" },
					{
						role: "admin",
						label: "criar_e_gerir_utilizadores.invite_popup.value.admin",
					},
				],
				name: "role",
			},
			{
				label: "menu.barra_esquerda.level_2.teams",
				hasBorder: false,
				type: "select-multiple",
				items: state.teams.filter((team) => team.status === "active"),
				required: false,
				name: "teams",
			},
		],
		additionalStepItems: [{ type: "team-permissions", items: state.teams }],
		additionalStepTitle: "criar_e_gerir_utilizadores.invite_popup.button.set_team_permissions_m",
		additionalSteps: 1,
		required: ["name", "email", "teams"],
		submit: "criar_e_gerir_utilizadores.invite_popup.button.send_invite_m",
		closePopup: togglePopup,
		returnURL: "/teams/user-management",
		formAction: "",
		hasOutput: false,
		onSubmit: inviteUser,
	};

	const editSingleUserPopupData = {
		title: "criar_e_gerir_utilizadores.notification.button.edit_user",
		popupItems: [
			{
				label: "criar_e_gerir_templates.table.header_text.name",
				hasBorder: false,
				type: "input",
				name: "username",
			},
			{
				label: "criar_e_gerir_utilizadores.table.header_text.role",
				hasBorder: false,
				type: "switch",
				infoVals: {
					member: "criar_e_gerir_utilizadores.invite_popup.text.member_perm_info",
					admin: "criar_e_gerir_utilizadores.invite_popup.text.admin_perm_info",
				},
				items: [
					{
						role: "member",
						label: "criar_e_gerir_utilizadores.invite_popup.value.member",
					},
					{
						role: "admin",
						label: "criar_e_gerir_utilizadores.invite_popup.value.admin",
					},
				],
				name: "role",
			},
			{
				label: "menu.barra_esquerda.level_2.teams",
				hasBorder: false,
				type: "select-multiple",
				items: state.teams.filter((team) => team.status === "active"),
				required: false,
				name: "teams",
			},
		],
		additionalStepItems: [{ type: "team-permissions", items: state.teams }],
		additionalStepTitle: "criar_e_gerir_utilizadores.invite_popup.button.set_team_permissions_m",
		additionalSteps: 1,
		submit: "criar_e_gerir_utilizadores.invite_popup.button.save",
		closePopup: togglePopup,
		returnURL: "/teams/user-management",
		formAction: "",
		hasOutput: false,
		onSubmit: editUser,
	};

	const editMultipleUsersPopupData = {
		title: "user.edit",
		popupItems: [
			{
				label: "criar_e_gerir_utilizadores.table.header_text.role",
				hasBorder: false,
				type: "select-normal",
				items: [{ role: "viewer" }, { role: "member" }, { role: "admin" }],
				name: "role",
			},
			{
				label: "menu.barra_esquerda.level_2.teams",
				hasBorder: false,
				type: "select-multiple",
				items: state.teams.filter((team) => team.status === "active"),
				required: false,
				name: "teams",
			},
		],
		submit: "criar_e_gerir_utilizadores.invite_popup.button.save",
		closePopup: togglePopup,
		returnURL: "/teams/user-management",
		formAction: "",
		hasOutput: false,
		onSubmit: handleFormSubmission,
	};

	const resendInviteWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "criar_e_gerir_utilizadores.re-envite_popup.text1",
		message: "criar_e_gerir_utilizadores.re-envite_popup.text2",
		listData: [
			{
				title: "criar_e_gerir_utilizadores.re-envite_popup.text3",
				name: "username",
			},
		],
		submit: i18n.t("criar_e_gerir_utilizadores.table.button.resend_invite").split(" ")[0].toUpperCase(),
		closePopup: togglePopup,
		returnURL: "/teams/user-management",
		formAction: "",
		onSubmit: resendInvite,
	};

	const deleteWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "criar_e_gerir_utilizadores.delete_popup.text.confirm_question",
		message: "criar_e_gerir_utilizadores.delete_popup.text.confirm_text",
		listData: [
			{
				title: "criar_e_gerir_utilizadores.delete_popup.text.users",
				name: "username",
			},
		],
		submit: "criar_e_gerir_valores_de_parâmetros_de_campanha.delete_popup.button.delete_m",
		closePopup: togglePopup,
		returnURL: "/teams/user-management",
		formAction: "",
		onSubmit: deleteUser,
	};
	return {
		createPopupData,
		editSingleUserPopupData,
		editMultipleUsersPopupData,
		deleteWarningData,
		resendInviteWarningData,
	};
};

const teamManagementPopupData = (
	togglePopup,
	handleFormSubmission,
	handleWarningAction,
	state,
	createTeam,
	editTeam,
	deleteTeam
) => {
	const teamsPopupData = {
		title: "criar_e_gerir_equipas.create_popup.title.create_team",
		popupItems: [
			{
				label: "criar_e_gerir_templates.table.header_text.name",
				hasBorder: false,
				type: "input",
				required: true,
				name: "team_name",
			},
		],
		submit: "criar_e_gerir_equipas.header.button.create_team_m",
		closePopup: togglePopup,
		returnURL: "/teams/team-management",
		formAction: "",
		hasOutput: false,
		onSubmit: createTeam,
	};

	const editSingleTeamPopupData = {
		title: "criar_e_gerir_equipas.edit_popup.title.edit_team",
		popupItems: [
			{
				label: "criar_e_gerir_templates.table.header_text.name",
				hasBorder: false,
				type: "input",
				required: true,
				name: "team_name",
			},
			{
				label: "confirmação_links_bulk.table.header_text.status",
				hasBorder: false,
				type: "select-normal",
				items: [{ status: "active" }, { status: "inactive" }],
				name: "status",
			},
		],
		submit: "criar_e_gerir_equipas.edit_popup.button.save_changes_m",
		closePopup: togglePopup,
		returnURL: "/teams/team-management",
		formAction: "",
		hasOutput: false,
		onSubmit: editTeam,
	};

	const editMultipleTeamsPopupData = {
		title: "criar_e_gerir_equipas.edit_popup.title.edit_team",
		popupItems: [
			{
				label: "confirmação_links_bulk.table.header_text.status",
				hasBorder: false,
				type: "select-normal",
				items: [{ status: "Active" }, { status: "Inactive" }],
				name: "status",
			},
		],
		submit: "criar_e_gerir_equipas.edit_popup.button.save_changes_m",
		closePopup: togglePopup,
		returnURL: "/teams/team-management",
		formAction: "",
		hasOutput: false,
		onSubmit: editTeam,
	};

	const deleteWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "criar_e_gerir_equipas.delete_popup.text.confirm_question",
		message: "criar_e_gerir_equipas.delete_popup.text.confirm_info",
		listData: [
			{
				title: "team.teams",
				name: "team_name",
			},
		],
		submit: "criar_e_gerir_valores_de_parâmetros_de_campanha.delete_popup.button.delete_m",
		closePopup: togglePopup,
		returnURL: "/teams/team-management",
		formAction: "",
		onSubmit: deleteTeam,
	};
	return {
		teamsPopupData,
		editSingleTeamPopupData,
		editMultipleTeamsPopupData,
		deleteWarningData,
	};
};

const parameterPopupData = (toggleDeleteWarning, handleDeleteAction, deleteParameter, isCampaign) => {
	const deleteWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "criar_e_gerir_valores_de_parâmetros_utm.delete_popup.text.confirm_question",
		message: "criar_e_gerir_valores_de_parâmetros_utm.delete_popup.text.confirm_info",
		listData: [
			{
				title: "criar_e_gerir_parâmetros_campanha.parameter",
				name: "name",
			},
		],
		submit: "criar_e_gerir_valores_de_parâmetros_de_campanha.delete_popup.button.delete_m",
		closePopup: toggleDeleteWarning,
		returnURL: isCampaign ? "/campaigns/parameters" : "/links/parameters",
		formAction: "",
		onSubmit: deleteParameter,
	};

	const importPopupData = {
		title: "param.import_utm",
		message: "param.import_text_info",
		submit: "Importar",
		closePopup: toggleDeleteWarning,
		returnURL: "/links/parameters",
		formAction: "",
		onSubmit: handleDeleteAction,
	};

	return { deleteWarningData, importPopupData };
};

const parameterManagementPopupData = (toggleDeleteWarning, submitAction, handleDeleteAction, handleMigrateData) => {
	const confirmWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "alterar_password.confirm_popup.text.confirm_question",
		message: "criar_e_gerir_parâmetros_utm.confirm_popup.text.confirm_info",
		submit: "gerir_dependências_de_parâmetros_utm.param_dependency.button.save_m",
		closePopup: toggleDeleteWarning,
		returnURL: "/links/parameter-management",
		formAction: "",
		onSubmit: submitAction,
	};
	const migrateContentWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "criar_e_gerir_parametros_utm.create_popup.text.content_confirm_question",
		message: "criar_e_gerir_parâmetros_utm.migrate_popup.text.confirm_info",
		submit: "criar_e_gerir_parâmetros_utm.migrate_popup.button.next_m",
		migrate: "utm_content",
		closePopup: toggleDeleteWarning,
		returnURL: "/links/parameter-management",
		formAction: "",
		onSubmit: () => handleMigrateData("Concat_Content"),
	};
	const migrateTermWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "criar_e_gerir_parametros_utm.create_popup.text.term_confirm_question",
		message: "criar_e_gerir_parâmetros_utm.migrate_popup.text.confirm_info",
		submit: "criar_e_gerir_parâmetros_utm.migrate_popup.button.next_m",
		migrate: "utm_term",
		closePopup: toggleDeleteWarning,
		returnURL: "/links/parameter-management",
		formAction: "",
		onSubmit: () => handleMigrateData("Concat_Term"),
	};
	const deleteWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "criar_e_gerir_valores_de_parâmetros_utm.delete_popup.text.confirm_question",
		message:
			"Se decidir apagar o parâmetro serão guardados no Trash e podem ser recuperados num prazo de 30 dias, ao fim dos quais serão automaticamente e definitivamente apagados.",
		listData: [
			{
				title: "Parâmetro:",
				name: "name",
			},
		],
		submit: "criar_e_gerir_valores_de_parâmetros_de_campanha.delete_popup.button.delete_m",
		closePopup: toggleDeleteWarning,
		returnURL: "/links/parameter-management",
		formAction: "",
		onSubmit: handleDeleteAction,
	};

	return {
		confirmWarningData,
		deleteWarningData,
		migrateContentWarningData,
		migrateTermWarningData,
	};
};

const campaignParameterPopupData = (toggleDeleteWarning, submitAction, handleDeleteAction) => {
	const confirmWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "alterar_password.confirm_popup.text.confirm_question",
		message: "criar_e_gerir_parâmetros_utm.confirm_popup.text.confirm_info",
		submit: "gerir_dependências_de_parâmetros_utm.param_dependency.button.save_m",
		closePopup: toggleDeleteWarning,
		returnURL: "/campaigns/parameter-management",
		formAction: "",
		onSubmit: submitAction,
	};
	const taxonomyConfirmWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "alterar_password.confirm_popup.text.confirm_question",
		message: "taxonomia_de_campanhas.confirm_popup.text.save_confirm_info",
		submit: "gerir_dependências_de_parâmetros_utm.param_dependency.button.save_m",
		closePopup: toggleDeleteWarning,
		returnURL: "/campaigns/campaign-taxonomy",
		formAction: "",
		onSubmit: submitAction,
	};
	const deleteWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "criar_e_gerir_valores_de_parâmetros_utm.delete_popup.text.confirm_question",
		message:
			"Se decidir apagar o parâmetro serão guardados no Trash e podem ser recuperados num prazo de 30 dias, ao fim dos quais serão automaticamente e definitivamente apagados.",
		listData: [
			{
				title: "Parâmetro:",
				name: "name",
			},
		],
		submit: "criar_e_gerir_valores_de_parâmetros_de_campanha.delete_popup.button.delete_m",
		closePopup: toggleDeleteWarning,
		returnURL: "/campaigns/parameter-management",
		formAction: "",
		onSubmit: handleDeleteAction,
	};

	return { confirmWarningData, taxonomyConfirmWarningData, deleteWarningData };
};

const linkShortenerPopupData = (
	togglePopup,
	handleFormSubmission,
	handleWarningAction,
	state,
	editShortener,
	deleteShortener,
	removeBitly,
	removeTinyURL
) => {
	const createPopupData = {
		title: "configurar_link_shorteners.add_bitly_popup.title.add_shortener",
		popupItems: [
			{
				label: "criar_e_gerir_campanhas.create_popup.field.shortener",
				hasBorder: false,
				type: "select-normal",
				items: [{ shortener: "Bitly" }, { shortener: "tinyURL" }],
				required: true,
				name: "shortener",
			},

			{
				label: "menu.barra_esquerda.level_2.teams",
				hasBorder: false,
				type: "select-multiple",
				items: state.teams.filter((team) => team.status === "active"),
				required: false,
				name: "teams",
			},
			{
				label: "criar_e_gerir_templates.table.header_text.name",
				hasBorder: true,
				type: "input",
				required: false,
				name: "name",
			},
			{
				hasBorder: false,
				type: "auth",
				required: false,
				name: "token",
			},
		],
		submit: "gerir_dependências_de_parâmetros_utm.param_dependency.button.save_m",
		closePopup: togglePopup,
		returnURL: "/links/link-shorteners",
		formAction: "",
		hasOutput: false,
		onSubmit: handleFormSubmission,
	};

	const editPopupData = {
		title: "configurar_link_shorteners.edit_popup.title.edit_link_short",
		popupItems: [
			{
				label: "criar_e_gerir_templates.table.header_text.name",
				hasBorder: false,
				type: "input",
				required: false,
				name: "name",
			},
			{
				label: "Access Token",
				hasBorder: false,
				type: "input",
				required: false,
				name: "access_token",
			},
			{
				label: "configurar_link_shorteners.add_bitly_popup.field.group",
				hasBorder: false,
				type: "select-normal",
				items: [],
				required: false,
				name: "group_selected",
			},
			{
				label: "dashboard.campaign_details.header_text.status",
				hasBorder: false,
				type: "select-normal",
				items: ["active", "inactive"],
				required: false,
				name: "status",
			},
			{
				label: "menu.barra_esquerda.level_2.teams",
				hasBorder: false,
				type: "select-multiple",
				items: state.teams.filter((team) => team.status === "active"),
				required: false,
				name: "teams",
			},
		],
		submit: "criar_e_gerir_utilizadores.invite_popup.button.save",
		closePopup: togglePopup,
		returnURL: "/links/link-shorteners",
		formAction: "",
		hasOutput: false,
		onSubmit: editShortener,
	};

	const removeBitlyData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "configurar_link_shorteners.remove_bitly_acc_popup.text.confirm_question",
		message: "configurar_link_shorteners.remove_bitly_acc_popup.text.confirm_info",
		listData: [
			{
				title: "Shortener(s):",
				name: "name",
			},
		],
		submit: "criar_e_gerir_valores_de_parâmetros_de_campanha.delete_popup.button.delete_m",
		closePopup: togglePopup,
		returnURL: "/links/link-shorteners",
		formAction: "",
		onSubmit: removeBitly,
	};

	const removeTinyURLData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "configurar_link_shorteners.remove_tinyurl_acc_popup.text.confirm_question",
		message: "configurar_link_shorteners.remove_tinyurl_acc_popup.text.confirm_info",
		listData: [
			{
				title: "Shortener(s):",
				name: "name",
			},
		],
		submit: "criar_e_gerir_valores_de_parâmetros_de_campanha.delete_popup.button.delete_m",
		closePopup: togglePopup,
		returnURL: "/links/link-shorteners",
		formAction: "",
		onSubmit: removeTinyURL,
	};

	const deleteWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "configurar_link_shorteners.delete_popup.text.confirm_question",
		message: "criar_e_gerir_parâmetros_utm.confirm_popup.text.confirm_info", //"configurar_link_shorteners.delete_popup.text.confirm_info",
		listData: [
			{
				title: "Shortener(s):",
				name: "name",
			},
		],
		submit: "criar_e_gerir_valores_de_parâmetros_de_campanha.delete_popup.button.delete_m",
		closePopup: togglePopup,
		returnURL: "/links/link-shorteners",
		formAction: "",
		onSubmit: deleteShortener,
	};

	return {
		createPopupData,
		editPopupData,
		deleteWarningData,
		removeBitlyData,
		removeTinyURLData,
	};
};

const billingContactsPopupData = (togglePopup, handleFormSubmission, handleWarningAction, state) => {
	const createPopupData = {
		title: "acc.add_factur_contact",
		popupItems: [
			{
				label: "criar_e_gerir_templates.table.header_text.name",
				hasBorder: false,
				type: "select",
				noSome: true,
				items: state.users,
				required: true,
				populates: state.users,
				populate_fields: ["email"],
				name: "username",
			},
			{
				label: "criar_e_gerir_utilizadores.table.header_text.email",
				hasBorder: false,
				type: "select",
				noSome: true,
				items: state.users,
				populates: state.users,
				populate_fields: ["username"],
				name: "email",
			},
		],
		submit: "gerir_dependências_de_parâmetros_utm.param_dependency.button.save_m",
		closePopup: togglePopup,
		returnURL: "/settings/billings/contacts",
		formAction: "",
		hasOutput: false,
		onSubmit: handleFormSubmission,
	};

	const editSingleUserPopupData = {
		title: "Editar Contacto",
		popupItems: [
			{
				label: "criar_e_gerir_templates.table.header_text.name",
				hasBorder: false,
				type: "select",
				noSome: true,
				items: state.users,
				required: true,
				populates: state.users,
				populate_fields: ["email"],
				name: "username",
			},
			{
				label: "criar_e_gerir_utilizadores.table.header_text.email",
				hasBorder: false,
				type: "select",
				noSome: true,
				items: state.users,
				populates: state.users,
				populate_fields: ["username"],
				name: "email",
			},
		],
		submit: "criar_e_gerir_utilizadores.invite_popup.button.save",
		closePopup: togglePopup,
		returnURL: "/billings/contacts",
		formAction: "",
		hasOutput: false,
		onSubmit: handleFormSubmission,
	};

	const deleteWarningData = {
		title: "confirmação_links_bulk.confirm_popup.title.confirma",
		header: "criar_e_gerir_utilizadores.delete_popup.text.confirm_question",
		message: "campanhas_arquivadas.delete_popup.text.delete_confirm_info",
		listData: [
			{
				title: "criar_e_gerir_utilizadores.re-envite_popup.text3",
				name: "username",
			},
		],
		submit: "criar_e_gerir_valores_de_parâmetros_de_campanha.delete_popup.button.delete_m",
		closePopup: togglePopup,
		returnURL: "/billings/contacts",
		formAction: "",
		onSubmit: handleWarningAction,
	};
	return {
		createPopupData,
		editSingleUserPopupData,
		deleteWarningData,
	};
};

const mainPopupData = (handleFormSubmission, handleRecoverSubmission) => {
	const emailConfirmPopupData = {
		title: "authentication.confirm_popup.field.confirm_email",
		popupItems: [
			{
				label: "authentication.confirm_popup.field.password",
				hasBorder: false,
				type: "password",
				name: "password",
				value: "",
				subtext: true,
			},
			{
				label: "authentication.confirm_popup.field.confirm_password",
				hasBorder: false,
				type: "password",
				name: "password_confirm",
				value: "",
			},
		],
		required: ["password", "password_confirm"],
		submit: "importação_de_parâmetros_de_campanha.popup.button.confirm_m",
		closePopup: null,
		returnURL: "/",
		formAction: "",
		hasOutput: false,
		onSubmit: handleFormSubmission,
	};

	const recoverAccountPopupData = {
		title: "recover_password.header.text.title",
		popupItems: [
			{
				label: "alterar_password.setting.text.new_password",
				hasBorder: false,
				type: "password",
				name: "password",
				value: "",
				subtext: true,
			},
			{
				label: "alterar_password.setting.text.confirm_new_password",
				hasBorder: false,
				type: "password",
				name: "password_confirm",
				value: "",
			},
		],
		required: ["password", "password_confirm"],
		submit: "authentication.recover_popup.title.recover_password",
		closePopup: null,
		returnURL: "/",
		formAction: "",
		hasOutput: false,
		onSubmit: handleRecoverSubmission,
	};

	return {
		emailConfirmPopupData,
		recoverAccountPopupData,
	};
};

export {
	mainPopupData,
	activeCampaignPopupData,
	archivedCampaignPopupData,
	campaignLinksPopupData,
	templatesPopupData,
	deletedParametersPopupData,
	deletedTemplatesPopupData,
	deletedCampaignsLinksPopupData,
	userManagementPopupData,
	teamManagementPopupData,
	parameterPopupData,
	parameterManagementPopupData,
	campaignParameterPopupData,
	linkShortenerPopupData,
	billingContactsPopupData,
};
