const orgPermissions = {
	admin: {
		manageOrg: true,
		viewAllTeams: true,
		manageAllTeams: true,
		viewAllTeamsLinks: true,
		createTeams: true,
		manageAllUsers: true,
		manageBillings: true,
		manageLinkShorteners: true,
		manageSyntaxConfig: true,
		manageTeam: true,
		manageParameters: true,
		manageCampaignParameters: true,
		addCampaignParameters: true,
		importCampaignParameters: true,
		manageCampaignTaxonomy: true,
		importParameters: true,
	},
	member: {},
};

const teamPermissions = {
	admin: {},
	leader: {
		manageTeamUsers: true,
		manageCampaigns: true,
		manageCampaignParameters: true,
		addCampaignParameters: true,
		importCampaignParameters: true,
		manageCampaignTaxonomy: true,
		manageLinks: true,
		manageTemplates: true,
		viewArchivedCampaigns: true,
		manageArchivedCampaigns: true,
		addParameters: true,
		viewCampaignLinkTrash: true,
		manageCampaignLinkTrash: true,
		viewTemplateTrash: true,
		manageTemplateTrash: true,
		sendCampaignReminder: true,
	},
	member: {
		manageLinks: true,
		viewArchivedCampaigns: true,
		viewCampaignLinkTrash: true,
		viewTemplateTrash: true,
		manageTemplates: true,
	},
	viewer: {},
};

const permissionsManager = (type, role, permission) => {
	if (!role || !permission) {
		return false;
	}
	if (type === "org") {
		return orgPermissions[(role && "admin") || "member"][permission];
	} else if (type === "team") {
		if (permission === "addParameters") {
			console.log("ACCESSED HERE");
		}
		return teamPermissions[role][permission];
	}
};

export default permissionsManager;
